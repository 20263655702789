exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutme-tsx": () => import("./../../../src/pages/aboutme.tsx" /* webpackChunkName: "component---src-pages-aboutme-tsx" */),
  "component---src-pages-gatsbyexample-tsx": () => import("./../../../src/pages/gatsbyexample.tsx" /* webpackChunkName: "component---src-pages-gatsbyexample-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-community-events-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/community-events/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-community-events-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-flex-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/kuto-flex/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-flex-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-marketing-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/kuto-marketing/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-marketing-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-marketing-site-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/kuto-marketing-site/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-marketing-site-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-store-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/kuto-store/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-kuto-store-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-meridian-manager-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/meridian-manager/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-meridian-manager-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-octopus-industries-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/octopus-industries/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-octopus-industries-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-tags-manager-index-mdx": () => import("./../../../src/pages/work/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/tags-manager/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-tsx-content-file-path-projects-tags-manager-index-mdx" */)
}

